<template>
    <div class="details-box">
        <div id="topnav">
            <div class="topmenu">
                <div class="navigation">
                    <ul class="bar">
                        <li
                            v-for="(item, index) in navList"
                            :key="index"
                            @click="handleClick(index)"
                            :class="{ navActive: index == change || item.url == $route.path }"
                            >
                            <router-link :to="item.url" exact router-link-active>
                                {{ item.text }}
                            </router-link>
                            <i class="dot"></i>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container">
            <h3 class="title-info">
                <img src="../../assets/icon/laboratory-icon.png" alt="">
                <span>强制性认证实验室信息</span>
            </h3>
            <div class="detailed-info">
                <img src="../../assets/bj-img.png" alt="">
                <div>
                    <div v-if="organdetailData.laboratoryName">
                        <span>实验室名称：</span>
                        <span>{{organdetailData.laboratoryName}}</span>
                    </div>
                    <div v-if="organdetailData.laboratoryNo">
                        <span>实验室编号：</span>
                        <span>{{organdetailData.laboratoryNo}}</span>
                    </div>
                    <div class="display-style">
                        <div v-if="organdetailData.address">
                            <span>所在地址：</span>
                            <span>{{organdetailData.address}}</span>
                        </div>
                        <div v-if="organdetailData.site">
                            <span>网址：</span>
                            <span @click="goPage(organdetailData.site)" class="common-color website-style">{{organdetailData.site}}</span>
                        </div>
                    </div>
                    <div v-if="organdetailData.unit"> 
                        <span>所属单位：</span>
                        <span class="common-color">{{organdetailData.unit}}</span>
                    </div>
                    <!-- <div v-if="organdetailData.regAuthority">
                        <span>登记机关：</span>
                        <span>{{organdetailData.regAuthority}}</span>
                    </div> -->
                    <div class="display-style">
                        <div v-if="organdetailData.contact">
                            <span>联系人员：</span>
                            <span>{{organdetailData.contact}}</span>
                        </div>
                        <div v-if="organdetailData.contactTel">
                            <span>联系电话：</span>
                            <span>{{organdetailData.contactTel}}</span>
                        </div>
                    </div>
                    <div v-if="organdetailData.specifiedScope" class="scroll-list">
                        <span>指定业务范围：</span>
                        <div class="scroll-box">
                            <div class="scroll-info">
                                <p>
                                    <span v-html="changeBr(organdetailData.specifiedScope)" class="scroll-scope">{{organdetailData.specifiedScope}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="footer">
            <a target="_blank" href="#">版权所有：全国认证认可信息公示平台</a>
            <span>服务邮箱：service@rzgs.net</span>
        </footer>
    </div>
</template>

<script>
const axios = require("axios");
export default {
    data() {
        return {
            change: null,
            navList: [
                { url: '/', text: '首页' },
                { url: '/result', text: '获证查询' },
                { url: '/professionalInstitutions', text: '从业机构' },
                { url: '/inspectionStandard', text: '检验标准' }
            ],
            organdetailData:{}
        };
    },
    methods: {
        goPage (url) {
            window.open('//'+url)
        },
        handleClick: function (index) {
            this.change = index
        },
        checkRouterLocal(path) {
            this.change = this.navList.findIndex(item => item.url === path);
            if(path == '/certifiedLaboratory'){
                this.change = 2
            }
        },
        getdata() {
            axios({
                method: "get",
                url:
                process.env.VUE_APP_BASE_URL +
                "/rzgs/organdetail?id=" + this.$route.query.id
            }).then((response) => {
                if (response.data.status === 0) {
                    this.organdetailData = response.data.data;
                }
            });
        },
        changeBr: function(content){
            if(content){
                content = content.replace(/;/g, '</br>')
                return content
            }
        }
    },
    mounted() {
        this.getdata();
        let newRouter = this.$router.currentRoute.fullPath
        this.navList.forEach((item, index) => {
            if (newRouter === item.url) {
                this.change = index
            }
        })
        if (this.$route.path == '/') {
            this.change = 0
        }else if(this.$route.path == '/certifiedLaboratory'){
            this.change = 2
        }
    },
    watch: {
        $route: function (to) {
            if (to.path == '/') {
                this.change = 0
            }
            let path = this.$route.path;
            this.checkRouterLocal(path);
        }
    }
};
</script>

<style lang="scss" scoped>
.details-box{
    width: 100%;
    #topnav {
    position: relative;
    width: 100%;
    height: 400px;
    margin: 0 auto;
    background: url('../../assets/banner.jpg') no-repeat 0 0;
    background-size: 100% 100%;
    }
    .topmenu {
        position: absolute;
        left:0;
        bottom:22px;
        width: 100%;
    }
    .topmenu .navigation{
        width: 1200px;
        margin:0 auto;
    }
    .topmenu .bar {
        margin:0 auto;
        text-align: center;
        font-family: 'PingFangSC-Regular';
    }

    .topmenu li {
        position: relative;
        display: inline-block;
        width: 128px;
        height: 44px;
        line-height: 44px;
        margin-right:48px;
        text-align: center;
        letter-spacing: normal;
        background: rgba(255,255,255,0.7);
    }
    .topmenu li a {
        display: inline-block;
        width: 100%;
        font-size: 16px;
        color: #323233;
        text-align: center;
    }
    .topmenu li .dot{
        position: absolute;
        left:50%;
        margin-left:-6px;
        bottom:-6px;
        width:0; 
        height:0; 
        border-left:6px solid transparent;
        border-right:6px solid transparent;
        border-top:6px solid hsla(0,0%,100%,.7);
    }
    .topmenu .bar .navActive{
        position: relative;
        background-color: #031B57;
    }
    .topmenu .bar .navActive .dot{
        position: absolute;
        left:50%;
        margin-left:-6px;
        bottom:-6px;
        width:0; 
        height:0; 
        border-left:6px solid transparent;
        border-right:6px solid transparent;
        border-top:6px solid #031B57;
    }
    .topmenu .bar .navActive>a{
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 3px;
    }
    .container {
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        .title-info{
            margin:40px 0px 24px 0px;
        }
        .title-info>img{
            width: 28px;
            height: 28px;
            background-size: 100% 100%;
            vertical-align: middle;
            margin-right:7px;
        }
        .title-info>span{
            font-size: 24px;
            font-weight: 500;
            color: #323233;
            vertical-align: middle;
            letter-spacing: 3px;
        }
        .detailed-info{
            position: relative;
            width: 100%;
            min-height: 304px;
            margin-bottom: 96px;
            background-color: #fff;
        }
        .detailed-info>img{
            position: absolute;
            right:0;
            bottom:0;
            width: 304px;
            height: 304px;
            background-size: 100% 100%;
            z-index: 10;
        }
        .detailed-info>div{
            position: relative;
            padding:32px 32px 0px 32px;
            z-index: 1000;
        }
        .detailed-info>div .names-style{
            margin-top:32px;
        }
        .detailed-info>div .display-style{
            width: 100%;
            padding-bottom: 0 !important;
            div{
                display: inline-block;
                width: 50%;
                padding-bottom: 32px;
                span:nth-child(1){
                    color: #969799;
                }
            }
        }
        .detailed-info>div .category-field{
            .category-bomb{
                display: inline-block;
                width: 854px;
                height: 320px;
                vertical-align: top;
                background: #FFFFFF;
                box-shadow: 0px 0px 16px rgba(200, 201, 204, 0.5);
                opacity: 1;
                .category-info{
                    width: 822px;
                    height: 296px;
                    padding:0px 0px 12px 16px;
                    margin-top:4px;
                    overflow-y: auto;
                    .category-list{
                        display: block;
                        h3{
                            margin:0;
                            padding-top:8px;
                            font-size: 16px;
                            font-weight: 500;
                            color: #323233;
                            .dots{
                                display: inline-block;
                                width: 6px;
                                height: 6px;
                                margin-right:8px;
                                background: linear-gradient(90deg, #4882F8 0%, #C9D3FC 100%);
                                border-radius: 50%;
                                opacity: 1;
                                vertical-align: middle;
                            }
                        }
                        span{
                            display: block;
                            margin:4px 0px 0px 14px;
                            font-size: 14px;
                            font-weight: 400;
                            color: #323233;
                        }
                    }
                }

            }
        }
        .detailed-info>div>div{
            padding-bottom: 32px;
        }
        .detailed-info>div>div .common-color{
            color:#10439B !important;
        }
        .detailed-info .website-style{
            cursor: pointer;
        }
        .detailed-info>div>div .code-status{
            display: inline-block;
            width: 36px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            background: #00B01B;
            opacity: 1;
            border-radius: 2px;
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
        }
        .detailed-info>div>div .scroll-box{
            display: inline-block;
            width: 854px;
            height: 98px;
            background: #FFFFFF;
            font-size: 16px;
            font-weight: 400;
            color: #323233;
            box-shadow: 0px 0px 16px rgba(200, 201, 204, 0.5);
            opacity: 1;
            vertical-align: top;
            .scroll-info{
                width: 822px;
                height: 74px;
                padding:0px 0px 12px 16px;
                margin-top:5px;
                overflow-y: auto;
                p{
                    .scroll-scope{
                        line-height: 30px;
                    }
                }
            }
        }
        .scroll-info::-webkit-scrollbar,
        .category-info::-webkit-scrollbar{
            width: 4px;
            height: 4px;
        }
        .scroll-info::-webkit-scrollbar-thumb,
        .category-info::-webkit-scrollbar-thumb{
            border-radius:5px;
            box-shadow:inset005pxrgba(0,0,0,0.2);
            background:rgba(0,0,0,0.2);
        }
        .scroll-info::-webkit-scrollbar-track,
        .category-info::-webkit-scrollbar-track{
            box-shadow:inset005pxrgba(0,0,0,0.2);
            border-radius:0;
            background:rgba(0,0,0,0.1);
        }
        .detailed-info>div>div>span:nth-child(1),
        .detailed-info>div>.scope>span{
            font-size: 16px;
            font-weight: 400;
            color: #969799;
        }
        .detailed-info>div>div>span:nth-child(2){
            font-size: 16px;
            font-weight: 400;
            color: #323233;
        }
        .detailed-info>div>.scope{
            overflow: hidden;
        }
        .detailed-info>div>.scope>span{
            float: left !important;
        }
        .detailed-info>div>.scope>div{
            float: left !important;
            width: 1049px;
            line-height: 26px;
        }
    }
    .footer {
        width:100%;
        height:128px;
        line-height: 128px;
        text-align: center;
        background: url('../../assets/footer.jpg') no-repeat 0 0;
        background-size: 100% 100%;
    }
    .footer>a{
        margin-right:24px;
        font-size: 14px;
        font-weight: 400;
        color: #C8C9CC;
    }
    .footer>span{
        font-size: 14px;
        font-weight: 400;
        color: #C8C9CC;
    }
}
</style>